.sidebar {
  height: 100vh; 
  display: flex;
  flex-direction: column; 
  overflow-y: auto; 
  flex-shrink: 0;
  flex-grow: 0; 
}

.sidebar .logo {
  display: flex;
  justify-content: center; 
  align-items: center;
  padding: 20px; 
  margin: 0; 
}

.sidebar a {
  text-decoration: none;
}

.sidebar img {
  max-width: 100%; 
  height: auto;
}
